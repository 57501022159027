<!-- 공통코드 상세 -->
<template>
    <vvo v-slot="v">
        <div class="detail-container">
            <!-- 1. 데이터 없음 표시 영역 -->
            <div class="detail-placeholder" v-if="!detailInfo">
                <span class="detail-notification-icon">
                    <i class="fas fa-external-link-alt"></i>
                </span>
                <span class="detail-notification-text">공통코드를 선택하세요.</span>
            </div>
            <!-- 2.상세 정보 영역 -->
            <div style="height: calc(100% - 70px)" v-if="detailInfo">
                <!-- 2.1 상세 정보 헤더 영역 -->
                <div class="detail-header" v-if="detailInfo">
                    <div class="detail-title">
                        <span class="title">{{ viewTitle }}</span>
                        <span class="badge title1"> {{ detailInfo.codeName }} </span>
                        <span class="badge title2"> {{ detailInfo.commonCode }} </span>
                        <span class="badge others"> {{ detailInfo.codeCategory }} </span>
                    </div>
                    <div class="detail-control" v-if="!isNewMode">
                        <b-form-checkbox switch v-model="isEditMode" size="lg" />
                    </div>
                </div>

                <!-- 2.2 상세 정보 컨텐츠 표시 영역 -->
                <div class="detail-content" v-if="detailInfo">
                    <BaseInfoPanel :detailInfo="detailInfo" :mode="mode" />
                </div>
            </div>

            <!-- 3.하단 영역 -->
            <div class="detail-bottom">
                <!-- 3.1 자료 갱신 정보  -->
                <div class="detail-bottom-info" v-if="detailInfo">
                    <span>{{ `관리자: ${detailInfo.regWorker ? detailInfo.regWorker : "-"}` }}</span>
                    <span>{{ `생성일자: ${detailInfo.regDate ?? "-"}` }}</span>
                    <span>{{ `수정일자: ${detailInfo.modDate ?? "-"}` }}</span>
                </div>
                <!-- 3.2 하단 제어 버튼 -->
                <div class="detail-bottom-buttons">
                    <!-- @click="handleSave(detailInfo, isEditMode)" -->

                    <button
                        type="button"
                        class="btn btn-primary detail-btn"
                        v-if="isEditMode"
                        @click="onValidate(v, saveData)"
                    >
                        저장
                    </button>
                    <button
                        type="button"
                        class="btn btn-secondary detail-btn"
                        v-if="detailInfo"
                        @click="handleClose($event)"
                    >
                        닫기
                    </button>
                </div>
            </div>
        </div>
    </vvo>
</template>

<script>
import BaseInfoPanel from "./panels/BaseInfoPanel.vue";
// import CodeItemListPanel from "./panels/CodeItemListPanel.vue"

import backEndApi from "@src/api/backEndApi";
import moment from "moment";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    components: {
        BaseInfoPanel,
        // CodeItemListPanel
    },
    props: ["title", "commonCode"],
    async created() {},
    mounted() {},
    data() {
        return {
            detailInfo: null,
            mode: "view", // 편집모드 : edit/view

            isBaseInfoPanelOpen: true,
            isCodeItemListPanelOpen: false,
            popupMessages,
        };
    },
    computed: {
        isEditMode: {
            get() {
                return this.mode == "edit" || this.mode == "new";
            },
            set(value) {
                this.mode = value ? "edit" : "view";
            },
        },
        isNewMode() {
            return this.mode === "new";
        },
        isViewMode() {
            return this.mode === "view";
        },
        viewTitle() {
            return this.title;
        },
    },
    watch: {
        commonCode() {
            if (!this.commonCode) {
                // clear detailInfo
                this.clearData();
            } else {
                // load detailInfo
                this.loadData();
            }
        },
    },
    methods: {
        // list-Detail Common Functions

        async notifyCancelEdit() {
            // return true : Edit Cancel & Proceed Next Step
            //        false: Stop.
            if (this.isEditMode) {
                const result = await this.alertConfirmWarning("편집중인 내용을 무시하고 종료할까요?");
                if (result.value) return true;
                else return false;
            }

            return true;
        },

        // list-Detail Data Functions
        newData() {
            this.mode = "new";
            this.detailInfo = {
                commonCode: "",
                codeName: "",
                codeCategory: "",
                oprtnCodeYn: "N",
                enabled: "Y",
                etcInfo1: "",
                etcInfo2: "",
                etcInfo3: "",
                etcInfo4: "",
                etcInfo5: "",
                desc: "",
                codeItems: [],
                action: "",
            };

            return this.detailInfo;
        },

        async loadData() {
            try {
                let result = await backEndApi.commonCode.getCommonCode(this.commonCode);
                if (result.status == 200) {
                    result.data.regDate = result.data.regDate
                        ? moment(result.data.regDate).format("YYYY-MM-DD HH:mm:ss")
                        : null;
                    result.data.modDate = result.data.modDate
                        ? moment(result.data.modDate).format("YYYY-MM-DD HH:mm:ss")
                        : null;
                    this.detailInfo = { ...result.data };
                    this.mode = "view";
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                this.alertNoti(popupMessages.COMMON_NO_SEARCH_DATA_POPUP_MESSAGE);
                console.error(e);
            }
        },

        clearData() {
            this.detailInfo = null;
            this.mode = "view";

            this.$forceUpdate();
        },
        formattedDesc() {
            if (!this.detailInfo.desc?.trim().length) {
                let codeItemText = this.detailInfo.codeItems
                    .filter((item) => item.enabled == "Y")
                    .map((item) => `${item.codeName}(${item.codeItem})`)
                    .join(",");
                let descText = `${this.detailInfo.codeName}(${this.detailInfo.commonCode}): ${codeItemText}`;
                this.detailInfo.desc = descText;
            }
        },
        async saveData() {
            try {
                this.formattedDesc();
                let result = await backEndApi.commonCode.saveCommonCode(this.detailInfo);
                if (result.status == 200) {
                    await this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);

                    if (this.isNewMode) this.$emit("data-updated", "insert", this.detailInfo);
                    else this.$emit("data-updated", "update", this.detailInfo);

                    this.mode = "view";
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            }
        },

        async deleteData() {
            const result = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
            if (!result.value) return;

            const deletedCommonCode = this.detailInfo.commonCode;

            try {
                const commonCode = [deletedCommonCode];
                const result = await backEndApi.commonCode.deleteCommonCode(commonCode);
                if (result.status !== 200) {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                    return;
                }

                this.clearData();
                this.$emit("data-updated", "delete", deletedCommonCode);

                this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
            } catch (e) {
                console.error(e);
            }
        },

        getData() {
            return this.detailInfo;
        },

        // Private Event Handling Functions
        async handleSave(info) {
            if (this.isNewMode) info.action = "create";
            else info.action = "update";
            await this.saveData(info);
        },

        async handleClose(event) {
            const result = await this.notifyCancelEdit();
            if (!result) {
                event && event.preventDefault();
                return false;
            }
            // this.toggleEditMode();
            this.clearData();
            this.$emit("closed", this.commonCode);

            return true;
        },
    },
};
</script>

<style scoped>
/* detail area styles */
.detail-placeholder {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detail-notification-icon {
    padding: 1rem;
    color: #444;
    font-size: 48px;
}

.detail-notification-text {
    font-size: 15px;
    font-weight: bold;
}

.detail-container {
    height: 100%;
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 10px;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    font-size: 12px;
}

.detail-header {
    margin-bottom: 0px !important;
    padding: 8px 16px 8px 16px;
    overflow: hidden;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 6px 6px 0px 0px;
    background-color: #f1f1f1;
    border: solid #ececec 1px;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
}

.detail-header .detail-title {
    padding: 0 !important;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.detail-content {
    height: Calc(100% - 50px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 2px;
    padding: 20px;
    overflow: hidden;
    background-color: white;
    border: solid #ececec 1px;
    border-radius: 0px 0px 6px 6px;
}

.detail-itemview {
    /* height: 200px; */
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
    border: solid #ececec 1px;
    /* border: solid red 1px; */
    border-radius: 0px 0px 6px 6px;
    /* padding: 1rem 1rem; */
}

.detail-control {
    margin-bottom: 8px !important;
    display: flex;
    justify-content: flex-end;
}

.detail-menu {
    font-size: 0.8rem;
    font-weight: bold;
}

.detail-bottom {
    margin-top: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 40 px;
}

.detail-bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 4px;
}

.detail-btn {
    /* height: 50px; */
    flex: 1;
    margin: 0px 4px 0px 4px;
}

.detail-bottom-info {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px 8px 4px 8px;
}

/* input form styles */
.title {
    /* font-size: 14px; */
    font-weight: 600;
    margin-right: 20px;
}

.title-icon {
    font-size: 1rem !important;
}

.input-form {
    width: 100%;
    /* 부모 요소에 꽉 맞춤 */
    box-sizing: border-box;
    /* 입력 폼 요소의 패딩과 보더를 포함하여 크기를 계산 */
}

.input-form input {
    width: 100%;
    /* 입력 요소가 부모 요소에 꽉 맞춤 */
    padding: 10px;
    /* 선택적으로 패딩 설정 */
    margin-bottom: 10px;
    /* 선택적으로 아래쪽 여백 추가 */
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}

/* Badge styles */
.badge {
    font-size: 12px;
    font-weight: 500;
    margin-right: 4px;
    display: inline-block;
    min-width: 20px;
    padding: 0px 10px 0px 10px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    /* background-color: #777; */
    line-height: 22px;
    border-radius: 8px;
}

.badge.title1 {
    background-color: #ff6d00;
    color: #fff;
}

.badge.title2 {
    background-color: #546e7a;
    color: #fff;
}

.badge.others {
    background-color: #cfd8dc;
    color: #000;
}

/* Status Button Style */
.status-icon {
    font-size: 16px;
    color: #546e7a;
    background-color: #eceff1;
    border: solid #b0bec5 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.status-icon:hover {
    /* color: #546E7A; */
    background-color: #b0bec5;
}

.status-icon.active {
    color: #eceff1;
    background-color: #546e7a;
}
</style>
